const annotations = {
  yaxis: [
    // {
    //   y: 8200,
    //   borderColor: '#00E396',
    //   label: {
    //     borderColor: '#00E396',
    //     style: {
    //       color: '#fff',
    //       background: '#00E396',
    //     },
    //     text: 'Support',
    //   },
    // },
    // {
    //   y: 8600,
    //   y2: 9000,
    //   borderColor: '#000',
    //   fillColor: '#FEB019',
    //   opacity: 0.2,
    //   label: {
    //     borderColor: '#333',
    //     style: {
    //       fontSize: '10px',
    //       color: '#333',
    //       background: '#FEB019',
    //     },
    //     text: 'Y-axis range',
    //   },
    // },
  ],
  xaxis: [
    {
      x: new Date('16 Mar 2020').getTime(),
      strokeDashArray: 0,
      borderColor: '#775DD0',
      label: {
        borderColor: '#775DD0',
        style: {
          color: '#fff',
          background: '#775DD0',
        },
        text: 'PM says "now is the time for everyone to stop non-essential contact and travel"',
      },
    },
    {
      x: new Date('01 Jul 2019').getTime(),
      x2: new Date('31 Jul 2019').getTime(),
      fillColor: '#EEEEEE',
      opacity: 0.4,
      label: {
        borderColor: '#D50000',
        style: {
          fontSize: '14px',
          color: '#fff',
          background: '#D32F2F',
        },
        offsetY: -10,
        text: 'Record breaking heatwave',
      },
    },
    {
      x: new Date('23 Mar 2020').getTime(),
      x2: new Date('1 Jun 2020').getTime(),
      fillColor: '#B3F7CA',
      opacity: 0.4,
      label: {
        borderColor: '#B3F7CA',
        style: {
          fontSize: '10px',
          color: '#fff',
          background: '#00E396',
        },
        offsetY: -10,
        text: '1st National Lockdown',
      },
    },
    {
      x: new Date('5 Nov 2020').getTime(),
      x2: new Date('2  2020').getTime(),
      fillColor: '#B3F7CA',
      opacity: 0.4,
      label: {
        borderColor: '#B3F7CA',
        style: {
          fontSize: '10px',
          color: '#fff',
          background: '#00E396',
        },
        offsetY: -10,
        text: '2nd National Lockdown',
      },
    },
  ],
  points: [
    // {
    //   x: new Date('01 Dec 2017').getTime(),
    //   y: 8607.55,
    //   marker: {
    //     size: 8,
    //     fillColor: '#fff',
    //     strokeColor: 'red',
    //     radius: 2,
    //     cssClass: 'apexcharts-custom-class',
    //   },
    //   label: {
    //     borderColor: '#FF4560',
    //     offsetY: 0,
    //     style: {
    //       color: '#fff',
    //       background: '#FF4560',
    //     },

    //     text: 'Point Annotation',
    //   },
    // },
    // {
    //   x: new Date('08 Dec 2017').getTime(),
    //   y: 9340.85,
    //   marker: {
    //     size: 0,
    //   },
    //   image: {
    //     path: '../../assets/images/ico-instagram.png',
    //   },
    // },
  ],
};

const transformToTimeSeries = (data, dataset, appConfig) => {
  // console.log('data', data);
  const defaultOptions = {
    chart: {
      height: 350,
      type: 'line',
      id: 'areachart-2',
      events: {
        dataPointSelection: (e, chart, opts) => {
        },
        mounted: (chart) => {
        },
      },
    },
    annotations,
    dataLabels: {
      enabled: false,
    },
    stroke: {
      curve: 'straight',
    },
    grid: {
      padding: {
        right: 30,
        left: 20,
      },
    },
    title: {
      text: 'Line with explanations',
      align: 'left',
    },
    labels: [],
    xaxis: {
      type: 'datetime',
    },
    tooltip: {
      intersect: true,
      shared: false,
    },
    markers: {
      size: 5,
    },
  };
  const config = appConfig.datasets.find((item) => item.table_name === dataset.table_name);
  const options = defaultOptions;
  options.title.text = config.name;
  options.labels = data.map((item) => item.Date);
  const series1 = data.map((item) => Math.round(item.value));
  const series = [{ data: series1 }];

  return { options, series };
};

export default transformToTimeSeries;
